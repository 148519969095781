/*This code defines a React component called ColorBlindTile. It receives three props: title, children, and style.

The component renders a div element with specific styles. It has a flex container with a column direction and aligns items to the start. It has a padding of 16 pixels and a background color of white. The width and height are set to 100% of the parent element. Any additional styles passed through the style prop will be merged with these default styles.

Inside the main div, there is another div with specific styles. It also has a flex container with a column direction and aligns items to the start. It has a padding-bottom of 12 pixels and a gap of 2 pixels between its child elements. The width is set to 100% of the parent element.

Within this second div, there is an h3 element that displays the title prop value.

After that, there is another div with styles. It has a width and height of 100%, a background color of white, and it stretches to fill the available space using the alignSelf and flexGrow properties.

Finally, the component defines the prop types for title, children, and style. The title prop is required and must be a string. The children prop can be any valid React node. The style prop is optional and must be an object.

The component is exported as the default export of the module, allowing it to be imported and used in other parts of the codebase.*/


import React from 'react';
import PropTypes from 'prop-types';

const ColorBlindTile = ({ title, children, style }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        padding: 16,
        background: '#FFFFFF',
        width: '100%',
        height: '100%',
        ...style
      }}
    >
      <div
        style={{
          display: 'flex', 
          alignItems: 'center',
          paddingBottom: 5, 
          width: '100%', 
          maxHeight:'45px',
          justifyContent:'center',
        }}
        className='tile-title-section'
      >
        <h5 className='tile-title'>{title}</h5>
      </div>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'inline-flex',
          background: '#FFFFFF',
          alignSelf: 'flex-end',
          flexGrow: 1,
        }}
      >
        {children}
      </div>
    </div>
  );
};

ColorBlindTile.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  style: PropTypes.object,

};

export default ColorBlindTile;
