/*
This code is a React component called ColorSelectionPanel. It imports several dependencies, including React, useState, and various components and data files. It uses the useState hook to manage the state of numColors and selectedColors. The component renders a set of UI elements including a NumberInput component and multiple ColorSelectionRow components based on the selectedColors array.

The component defines event handlers for changes in the number of colors and individual color selections. These event handlers update the state and trigger a callback function onColorSelectionChange with the updated values. There is also a utility function getHexCode that retrieves the hexadecimal color code based on the selected color group and value.

Overall, this component allows the user to select a number of colors and modify their values, and it provides the corresponding hexadecimal codes for each color selection.
*/

import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import NumberInput from "./NumberInput";
import ColorSelectionRow from "./ColorSelectionRow";
import dsColorNames from "./dsColorNames.json";
import styled from "styled-components";

const StyledBox = styled(Box)`
  padding: 16px;
  background: #fff;
  & > :not(:first-child) {
    margin-top: 32px;
  }
`;

const ColorSelectionPanel = ({ onColorSelectionChange }) => {
  const [numColors, setNumColors] = useState(1);
  const [selectedColors, setSelectedColors] = useState([
    { group: "Gray", value: 50 },
  ]);

  const handleNumColorsChange = (value) => {
    setNumColors(value);
    if (value) {
      const newSelectedColors = Array(value)
        .fill(null)
        .map(
          (_, index) => selectedColors[index] || { group: "Gray", value: 50 }
        );

      setSelectedColors(newSelectedColors);
      onColorSelectionChange(newSelectedColors, value);
    }
  };

  const handleColorValueChange = (value) => {
    console.log("inside");
    if (value === "add") {
      setNumColors(numColors + 1);
      console.log("add");

      const newSelectedColors = Array(numColors + 1)
        .fill(null)
        .map(
          (_, index) => selectedColors[index] || { group: "Gray", value: 50 }
        );

      setSelectedColors(newSelectedColors);
      onColorSelectionChange(newSelectedColors, numColors + 1);
    }
    if (value === "subtract" && numColors > 1) {
      setNumColors(numColors - 1);

      const newSelectedColors = Array(numColors - 1)
        .fill(null)
        .map(
          (_, index) => selectedColors[index] || { group: "Gray", value: 50 }
        );

      setSelectedColors(newSelectedColors);
      onColorSelectionChange(newSelectedColors, numColors - 1);
    }
  };

  const handleColorChange = (index, group, value) => {
    const newSelectedColors = [...selectedColors];
    newSelectedColors[index] = { group, value };
    setSelectedColors(newSelectedColors);
    onColorSelectionChange(newSelectedColors, numColors);
  };

  const getHexCode = (group, value) => {
    const color = dsColorNames.find(
      (c) => c.Group === group && c.Value === value
    );
    return color ? color.Hex : "";
  };

  return (
    <Box>
      <NumberInput
        value={numColors}
        onChange={handleNumColorsChange}
        onButtonClick={handleColorValueChange}
      />

      <Box sx={{ marginBottom: "50px"  }}>
        {selectedColors.map((color, index) => (
          <ColorSelectionRow
            key={index}
            group={color.group}
            value={color.value}
            onChange={(group, value) => handleColorChange(index, group, value)}
          />
        ))}
      </Box>
      <Typography variant="h6">Selected Hex Codes</Typography>
      {selectedColors.map((color, index) => (
        <Typography
          key={index}
          style={{ marginTop: "8px", fontSize: "16px", lineHeight: "24px" }}
        >
          {getHexCode(color.group, color.value)}
        </Typography>
      ))}
    </Box>
  );
};

export default ColorSelectionPanel;
