/*
The DonutChart component uses the D3 library to create a donut chart. It receives data, colors, and a colorblind condition as props. The colors are adjusted based on the colorblind condition. It utilizes the useResizeObserver hook to dynamically resize the chart based on the container size. The chart is rendered using SVG and D3 functions to generate the donut chart with the provided data and adjusted colors.
*/

import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import colorBlind from "color-blind";
import useResizeObserver from "./useResizeObserver";

function adjustColors(colors, colorblindCondition) {
  if (!colorblindCondition) return colors;
  const simulateColorblindness = (color) => {
    try {
      return colorBlind[colorblindCondition](color);
    } catch (error) {
      console.error(`Error simulating colorblindness: ${error.message}`);
      return color;
    }
  };
  return colors.map((color) => simulateColorblindness(color));
}

const createDonutChart = (svg, data, colors, width, height) => {
  const radius = Math.min(width, height) / 2;
  const arc = d3
    .arc()
    .innerRadius(radius * 0.87)
    .outerRadius(radius);
  const pie = d3
    .pie()
    .value((d) => d.totalSales)
    .sort(null);
  const chartGroup = svg
    .append("g")
    .attr("transform", `translate(${width / 2}, ${height / 2})`);
  const arcs = chartGroup
    .selectAll(".arc")
    .data(pie(data))
    .enter()
    .append("g")
    .attr("class", "arc");
  arcs
    .append("path")
    .attr("d", arc)
    .attr("fill", (d, i) => colors[i % colors.length]);
  // Remove any unused arcs (in case data updates)
  arcs.exit().remove();
  svg
    .attr("viewBox", `0 0 ${width} ${height}`)
    .attr("width", "100%")
    .attr("height", "100%");
};

const DonutChart = ({ data, colors, colorblindCondition, numOfRows }) => {
  const adjustedColors = adjustColors(colors, colorblindCondition);
  const svgRef = useRef();
  const containerRef = useRef();
  const [containerSize] = useResizeObserver(containerRef);

  useEffect(() => {
    if (!data || !colors || !containerSize) return;
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();
    const containerWidth = containerSize.width;
    const containerHeight = containerSize.height;
    svg
      .attr("viewBox", `0 0 ${containerWidth} ${containerHeight}`)
      .attr("width", containerWidth)
      .attr("height", containerHeight);
    createDonutChart(
      svg,
      data.slice(0, numOfRows),
      adjustedColors,
      containerWidth,
      containerHeight
    );
  }, [data, adjustedColors, containerSize]);

  return (
    <div
      ref={containerRef}
      style={{ width: "100%", height: "100%", position: "relative" }}
    >
      <svg ref={svgRef} preserveAspectRatio="xMidYMid meet" />
    </div>
  );
};
export default DonutChart;
