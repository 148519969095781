//ColorSelectionRow.js

/*

This code imports modules including React, useMemo, Box, FormControl, InputLabel, MenuItem, Select, and TextField. It defines a functional component called "ColorSelectionRow" that takes "group", "value", "onChange" as its props. It returns a row composed of a Select element that shows unique groups of colors and calls "handleGroupChange" on change, and a TextField element that shows the value and calls "handleValueChange" on change. The component memoizes the unique groups of colors using the useMemo hook. The unique groups and the current group prop are used to create a MenuItem element list inside the Select element.

*/

import React, { useState, useMemo } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import dsColorNames from "./dsColorNames.json";
import { hex } from "chroma-js";
import { Typography } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';

const ColorSelectionRow = ({ group, value, onChange }) => {
  const [hexCode, setHexCode] = useState([]);
  const groupOptions = useMemo(() => {
    const uniqueGroups = new Set(dsColorNames.map((item) => item.Group));
    return Array.from(uniqueGroups);
  }, []);

  const handleGroupChange = (event) => {
    onChange(event.target.value, value);
  };

  const getHexCode = (group) => {
    const color = dsColorNames.find((c) => c.Group === group && c.Value === 50);
    return color ? color.Hex : "";
  };

  const handleValueChange = (event) => {
    onChange(group, parseInt(event.target.value, 10));
  };
  const useStyles = makeStyles((theme) => ({ 
    outlinedTextField: {
      '& .MuiOutlinedInput-input': {
        padding: '8px 10px', // Adjust the padding value as needed
        border: "1px solid rgba(25, 24, 35, 0.65)",
        borderRadius: "2px",
        background: "#fff",
        marginTop:"8px",  
      },
    },
    outlinedSelectbox: {
      '& .MuiOutlinedInput-input': {
        padding: '8px 10px', // Adjust the padding value as needed
        border: "1px solid rgba(25, 24, 35, 0.65)",
        borderRadius: "2px",
        background: "#fff",
        marginTop:"8px",  
        display:"flex",
        alignItems:'center', 
      },
      '& svg': { 
        marginTop:"4px",   
      },
    },
  }));
  const classes = useStyles();
  return (
    <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={2} marginBottom={2.5}>
      <FormControl>
        <Typography
          variant="label"
          id="color-select-label"
          className="form-group"
        >
          Color Group
        </Typography>
        <Select
          labelId="color-select-label"
          id="color-select"
          value={group}
          onChange={handleGroupChange}
          label=""
          className={classes.outlinedSelectbox} 
        >
          {groupOptions.map((group) => (
            <MenuItem className="select-color" key={group} value={group}>
              <span
                className="colored-circle"
                style={{ background: `${getHexCode(group)}` }}
              ></span>{" "}
              {group}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <Typography variant="label" className="form-group">
          Value
        </Typography>
        <TextField
          id="color-value-input"
          type="number"
          value={value}
          label=""
          variant="outlined"
          inputProps={{ min: 10, max: 100, step: 10 }}
          onChange={handleValueChange}
          fullWidth 
          className={classes.outlinedTextField}
        />
      </FormControl>
    </Box>
  );
};

export default ColorSelectionRow;
