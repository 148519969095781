import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import ColorBlindTile from "./ColorBlindTile";
import StackedBarChart from "./StackedBarChart";
import DonutChart from "./DonutChart";
import LineChart from "./LineChart";

const VisualizationSection = ({
  visualizationType,
  data,
  colors,
  numOfRows,
}) => {
  const [tileSize, setTileSize] = useState(null);

  useEffect(() => {
    const updateTileSize = () => {
      const screenWidth = window.innerWidth;
      const availableWidth = screenWidth  ;
      const width = availableWidth / 5;
      setTileSize(width);
    };

    updateTileSize();
    window.addEventListener("resize", updateTileSize);

    return () => {
      window.removeEventListener("resize", updateTileSize);
    };
  }, []);

  // Define the list of color blindness conditions
  const colorBlindConditions = [
    "Normal",
    "Protanomaly",
    "Protanopia",
    "Deuteranomaly",
    "Deuteranopia",
    "Tritanomaly",
    "Tritanopia",
    "Achromatopsia",
    "Achromatomaly",
  ];

  // Render the appropriate visualization based on the visualizationType prop
  const renderVisualization = (colorblindCondition) => {
    const lowercasedCondition = colorblindCondition.toLowerCase();
    switch (visualizationType) {
      case "StackedBar":
        return (
          <StackedBarChart
            data={data}
            colors={colors}
            colorblindCondition={lowercasedCondition}
            numOfRows={numOfRows}
          />
        );
      case "Donut":
        return (
          <DonutChart
            data={data}
            colors={colors}
            colorblindCondition={lowercasedCondition}
            numOfRows={numOfRows}
          />
        );
      case "Trend":
        return (
          <LineChart
            data={data}
            colors={colors}
            colorblindCondition={lowercasedCondition}
            numOfRows={numOfRows}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridTemplateRows: "repeat(3, 1fr)",
        gap: "30px", // Adjust the gap to prevent overlapping
        padding: 0,
        width: "100%",
        height: "100%", 
      }}
      className="grid-visualization"
    >
      {colorBlindConditions.map((condition) => (
        <div
          key={condition}
          style={{
            paddingBottom: "calc(70% - 0px)", // Maintain aspect ratio (square)
            position: "relative", // Allow child to fill the container
            border:' 1px solid rgba(25, 24, 35, 0.15)',
            borderRadius:'4px',
            overflow:'hidden',
            background:'#FFFFFF',
            padding:'0px'
          
          }}
          className="tile-size"
        >
          <ColorBlindTile
            key={condition}
            title={condition}
            style={{ width: tileSize, height: tileSize ,}}
          
          >
            <div
              style={{
                position: "absolute", // Fill the container
                top: 70,
                right: 0,
                bottom: 20,
                left: 0,
              }}
            >
              {renderVisualization(condition)}
            </div>
          </ColorBlindTile>
        </div>
      ))}
    </div>
  );
};

export default VisualizationSection;
