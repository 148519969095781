/* 

Here is the explanation for the code below:

1. The adjustedColors variable is declared to store the result of the adjustColors function.

2. The adjustColors function takes in the original colors and the colorblindCondition. 

If the colorblindCondition is not truthy, the original colors are returned. If the colorblindCondition is truthy, the colors are adjusted accordingly.

3. The useEffect hook is used to render the line chart. If the data or the colors are not truthy, the function is returned. The svg is selected using the useRef hook. The svg is then cleared by removing all its children. The createLineChart function is invoked to create the line chart.

4. The createLineChart function takes in the svg, the data, and the adjusted colors. The svg dimensions are calculated and the x and y scales are created. The line function is created. The chartGroup is created and the x and y axes are added to the chartGroup.

5. The data is formatted so that the x and y values can be used by the line function. The data is grouped by the fruit name.

6. The x and y scales are set to the extent of the months and the sales values.

7. The x axis is formatted to display the month names and the years. The y axis is formatted to display only the sales values.

8. The x and y axis ticks are formatted to display as dashed lines.

9. The data is looped through and the line is drawn for each fruit. The colors are adjusted accordingly. 

*/

import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import colorBlind from "color-blind";

function adjustColors(colors, colorblindCondition) {
  if (!colorblindCondition) return colors;

  const simulateColorblindness = (color) => {
    try {
      return colorBlind[colorblindCondition](color);
    } catch (error) {
      console.error(`Error simulating colorblindness: ${error.message}`);
      return color;
    }
  };

  return colors.map((color) => simulateColorblindness(color));
}

const LineChart = ({ data, colors, colorblindCondition, numOfRows }) => {
  const adjustedColors = adjustColors(colors, colorblindCondition);

  const svgRef = useRef();

  useEffect(() => {
    if (!data || !colors) return;
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();
    createLineChart(svg, data.slice(0, numOfRows), adjustedColors); // pass in the adjustedColors instead of the original colors
  }, [data, colors]);

  return (
    <svg
      ref={svgRef}
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMid meet"
    />
  );
};

export default LineChart;

const createLineChart = (svg, data, colors) => {
  const margin = { top: 16, right: 16, bottom: 16, left: 16 };
  const width =
    svg.node().getBoundingClientRect().width - margin.left - margin.right;
  const height =
    svg.node().getBoundingClientRect().height - margin.top - margin.bottom;

  const x = d3.scaleTime().range([0, width]);
  const y = d3.scaleLinear().range([height, 0]);

  const line = d3
    .line()
    .x((d) => x(d.time))
    .y((d) => y(d.value));

  const chartGroup = svg
    .append("g")
    .attr("transform", `translate(${margin.left}, ${margin.top})`);

  const months = d3.range(12).map((month) => new Date(2023, month));

  const formattedData = data.flatMap((fruit) =>
    fruit.sales.map((sale, index) => ({
      name: fruit.name,
      value: sale,
      time: months[index],
    }))
  );

  const nestedData = d3.groups(formattedData, (d) => d.name);

  x.domain(d3.extent(months));
  y.domain([
    d3.min(formattedData, (d) => d.value),
    d3.max(formattedData, (d) => d.value),
  ]);

  chartGroup
    .append("g")
    .attr("class", "x-axis")
    .attr("transform", `translate(0, ${height})`)
    .call(d3.axisBottom(x).tickSizeOuter(0).tickFormat(""));

  chartGroup
    .append("g")
    .attr("class", "y-axis")
    .call(d3.axisLeft(y).tickSizeOuter(0).tickFormat(""));

  chartGroup
    .selectAll(".x-axis .tick line")
    .attr("stroke", "#DDDCDE")
    .attr("stroke-width", 1)
    .attr("stroke-dasharray", "4 4");

  chartGroup
    .selectAll(".y-axis .tick line")
    .attr("stroke", "#DDDCDE")
    .attr("stroke-width", 1)
    .attr("stroke-dasharray", "4 4");

  nestedData.forEach(([name, fruitData], index) => {
    chartGroup
      .append("path")
      .datum(fruitData)
      .attr("fill", "none")
      .attr("stroke", colors[index % colors.length])
      .attr("stroke-width", 2)
      .attr("d", line);
  });
};
