import React, { useRef, useEffect } from "react";
import {
  select,
  scaleBand,
  scaleLinear,
  scaleOrdinal,
  max,
  stack,
  axisBottom,
  axisLeft,
} from "d3";
import colorBlind from "color-blind";

function adjustColors(colors, colorblindCondition) {
  if (!colorblindCondition) return colors;

  const simulateColorblindness = (color) => {
    try {
      return colorBlind[colorblindCondition](color);
    } catch (error) {
      console.error(`Error simulating colorblindness: ${error.message}`);
      return color;
    }
  };

  return colors.map((color) => simulateColorblindness(color));
}

const StackedBarChart = ({ data, colors, colorblindCondition, numOfRows }) => {
  const adjustedColors = adjustColors(colors, colorblindCondition);

  const svgRef = useRef();
  const containerRef = useRef();

  const createStackedBarChart = (svg, data, colors) => {
    // const keys = Object.keys(data[0]).slice(1);
    const keys = data.map((d) => d.name);

    if (keys.length === 0) {
      console.error("No keys found in the data");
      return;
    }

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const output = [];

    for (let i = 0; i < 12; i++) {
      const month = monthNames[i];
      const salesData = {};
      salesData["monthName"] = month;
      data.forEach((fruit) => {
        salesData[fruit.name] = fruit.sales[i];
      });
      output.push(salesData);
    }

    const margin = { top: 20, right: 20, bottom: 30, left: 40 };
    const width = svg.node().clientWidth - margin.left - margin.right;
    const height = svg.node().clientHeight - margin.top - margin.bottom;

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const stackGenerator = stack().keys(keys);
    const layers = stackGenerator(output);

    const extent = [
      0,
      max(layers, (layer) =>
        max(layer, (sequence) => sequence[1] + 100 * data.length)
      ),
    ];

    const x = scaleBand()
      .domain(output.map((d) => d.monthName))
      .rangeRound([0, width])
      .paddingInner(0.05)
      .align(0.1);

    const y = scaleLinear()
      .domain(extent)
      .rangeRound([height + 50, 0]);

    const z = scaleOrdinal(colors).domain(keys);

    g.append("g")
      .selectAll("g")
      .data(layers)
      .enter()
      .append("g")
      .attr("fill", (d) => z(d.key))
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("x", (d) => x(d.data.monthName))
      .attr("y", (d) => y(d[1]))
      .attr("height", (d) => y(d[0]) - y(d[1]))
      .attr("width", x.bandwidth());

    g.append("g")
      .attr("class", "axis")
      .attr("transform", `translate(0,${height + 50})`)
      .call(axisBottom(x));

    g.append("g")
      .attr("class", "axis")
      .call(axisLeft(y).ticks(null, "s"))
      .append("text")
      .attr("x", 2)
      .attr("y", y(y.ticks().pop()) + 0.5)
      .attr("dy", "0.32em")
      .attr("fill", "#000")
      .attr("font-weight", "bold")
      .attr("text-anchor", "start");
  };

  useEffect(() => {
    if (!data || !colors) return;
    const svg = select(svgRef.current);
    svg.selectAll("*").remove();

    const containerWidth = containerRef.current.clientWidth;
    const containerHeight = containerRef.current.clientHeight;
    svg.attr("viewBox", `0 0 ${containerWidth} ${containerHeight}`);

    createStackedBarChart(svg, data.slice(0, numOfRows), adjustedColors);
  }, [data, adjustedColors]);

  return (
    <div
      ref={containerRef}
      style={{ width: "100%", height: "100%", position: "relative" }}
    >
      <svg
        ref={svgRef}
        preserveAspectRatio="xMidYMid meet"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      />
    </div>
  );
};

export default StackedBarChart;
