/*The HeadingSection component renders a heading section with a title and buttons for selecting a visualization type. It uses the Material-UI library for the Typography, ButtonGroup, and Button components.

The component manages the state of the selectedType using the useState hook. When a button is clicked, the handleTypeChange function updates the selectedType and invokes the onVisualizationTypeChange callback with the selected type.

The component renders a div with a Typography component displaying the title. Below the title, there is a ButtonGroup component containing multiple Button components for each visualization type. The selectedType determines which button is disabled.

The HeadingSection component is exported as the default export of the module, allowing it to be imported and used in other parts of the codebase.*/

import React, { useState } from "react";
import { Typography, ButtonGroup, Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const HeadingSection = ({ onVisualizationTypeChange }) => {
  const [selectedType, setSelectedType] = useState("Donut");

  const handleTypeChange = (type) => {
    setSelectedType(type);
    onVisualizationTypeChange(type);
  };

  const useStyles = makeStyles({
    root: { 
      '& .MuiButtonGroup-root': { 
        boxShadow: 'none',  
        '&:hover': {
          boxShadow: 'none', 
        },
      },
      '& .MuiButton-root': {
        borderRadius: 24, 
        backgroundColor: '#fff',
        color: '#191823', 
        boxShadow: 'none',
        border:'0px none',
        marginRight:'6px', 
        textTransform:'inherit',
        '&:hover': {
          backgroundColor: '#191823',
        },
      },
      '& .MuiButton-containedPrimary': {
        backgroundColor: '#fff',
        border:'1px solid #191823',
        boxShadow: 'none',
        padding:'8px 16px',
        '&:hover': {
          backgroundColor: '#c00',
          color: '#fff', 
        },
        '&.active': {
          backgroundColor: '#000',
          color: '#fff', 
        },
      },
      '& .MuiButton-containedPrimary.Mui-disabled': { 
        borderRight:'1px solid #191823', 
        backgroundColor: '#000',
        color: '#fff', 
      },
      '& .MuiButton-containedSecondary': {
        backgroundColor: '#fff',
        boxShadow: '0px none',
        '&:hover': {
          backgroundColor: '#0c0',
        },
      },
    },
  });
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <Typography variant="h4" gutterBottom>
        Color Blind Simulation
      </Typography> */}
      <ButtonGroup color="primary" variant="contained">
        <Button
          onClick={() => handleTypeChange("Stacked Bar")}
          disabled={selectedType === "Stacked Bar"}
        >
          Stacked Bar
        </Button>
        <Button
          onClick={() => handleTypeChange("Donut")}
          disabled={selectedType === "Donut"}
        >
          Donut
        </Button>
        <Button
          onClick={() => handleTypeChange("Trend")}
          disabled={selectedType === "Trend"}
        >
          Trend
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default HeadingSection;
