import { useState, useEffect, useRef } from 'react';

const useResizeObserver = (ref) => {
  const [size, setSize] = useState(null);
  const observer = useRef(null);

  useEffect(() => {
    if (ref.current && !observer.current) {
      observer.current = new ResizeObserver((entries) => {
        const entry = entries[0];
        const { width, height } = entry.contentRect;
        setSize({ width, height });
      });
      observer.current.observe(ref.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
        observer.current = null;
      }
    };
  }, [ref]);

  return [size];
};

export default useResizeObserver;


/*

ok great... I'm having some trouble with the way it's being rendered. 

Ideally, each tile in the visualizationsection has a heading and a container for the chart. The chart itself should have the same height and width as the container for the chart. 

Right now, In my window... I see my ColorBlindTile is 470x470, which is fine cuz its a square. 
the div that is supposed to contain my chart is 406x392 but my svg which is supposed to be my chart is 470x940. Ideally ,in with this as an example, my svg should also be 406x392. does tht make sense?


*/