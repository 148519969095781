import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import sampleData from "./components/sampleData";
import HeadingSection from "./components/HeadingSection";
import VisualizationSection from "./components/VisualizationSection";
import ColorSelectionPanel from "./components/ColorSelectionPanel";
import dsColorNames from "./components/dsColorNames.json";
import "./styles/responsive.css";
 
 

function App() {
  const [visualizationType, setVisualizationType] = useState("Donut");
  const [colors, setColors] = useState(["#908da4"]);
  const [numOfRows, setNumOfRows] = useState(1);
  const getHexCode = (group, value) => {
    const color = dsColorNames.find(
      (c) => c.Group === group && c.Value === value
    );
    return color ? color.Hex : "";
  };

  const handleVisualizationTypeChange = (type) => {
    const newType = type.replace(" ", ""); // Remove the space in the type name
    setVisualizationType(newType);
  };

  const handleColorSelectionChange = (selectedColors, numColors) => {
    let tempColors;
    tempColors = selectedColors.map((color) => {
      return getHexCode(color.group, color.value);
    });
    setColors(tempColors);
    setNumOfRows(numColors);
  };

  return (
    <> 
     <header className="fixed-top">
     <div className="container-fuild">
      <div className="navbar">
      <div className="brand"> <strong>ColorShift</strong>  by DocuSign GDA | Intelligaia</div>
        {/* <div className="learn-more">Learn More</div> */}
      </div> 
      </div>
    </header>
    <div className="main-content">
    <div className="container">
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "flex-start", 
      }}
    >
      <Grid container  >
        <Grid item xs={12} md={9} sm={12}  style={{paddingTop:'30px'}} >
        <Grid item xs={12} md={12} sm={12}  style={{paddingLeft:'80px'}} className="xs-padding20" >
          <HeadingSection
            onVisualizationTypeChange={handleVisualizationTypeChange}
          />
          </Grid>
          <Grid item xs={12} md={12} sm={12} style={{padding:'80px', paddingTop:'30px'}} className="xs-padding20">
            <VisualizationSection
              visualizationType={visualizationType}
              data={sampleData.fruits} // Use sampleData.fruits instead of 'data'
              colors={colors}
              numOfRows={numOfRows}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={3} sm={12}  style={{background:'#fff', paddingTop:'30px'}}>
          <div className="sticky-area">
            <div className="sticky-div">
          <ColorSelectionPanel
            colors={colors}
            onColorChange={(index, newColor) => {
              const updatedColors = [...colors];
              updatedColors[index] = newColor;
              setColors(updatedColors);
            }}
            onColorSelectionChange={handleColorSelectionChange}
            setNumOfRows={setNumOfRows}
          />
          </div>
          </div>
        </Grid>
      </Grid>
    </Box>
    </div>
    </div>
    </>
  );
}

export default App;
