import React from "react";
import { TextField } from "@mui/material";
import { Box, Typography } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import Button from "@mui/material/Button"; 
import Stack from '@mui/material/Stack';
import { makeStyles } from '@material-ui/core/styles';
const NumberInput = (props) => {
  const handleChange = (event) => {
    if (event.target.value) {
      let value = parseInt(event.target.value, 10);
      if (value > 24) {
        value = 24;
      }
      props.onChange(value);
    } else {
      props.onChange(event.target.value);
    }
  };
 
  const useStyles = makeStyles((theme) => ({
    button1: {
      backgroundColor: '#fff',
      color: 'rgba(25, 24, 35, 0.65)', 
      border:'1px solid rgba(25, 24, 35, 0.65)',
      borderRadius:'2px', 
      maxWidth:'50px', 
      minWidth:'50px', 
      '&:hover': {
        backgroundColor: '#f1f1f1',
      },
    },
    button2: {
      backgroundColor: '#0069EC',
      color: 'white', 
      border:'1px solid #0069EC',
      borderRadius:'2px',  
      maxWidth:'50px', 
      minWidth:'50px',
      '&:hover': {
        backgroundColor: 'rgb(2 85 188)',
      },
    },
    outlinedTextField: { 
      '& .MuiOutlinedInput-input': {
        padding: '8px 10px', // Adjust the padding value as needed
        border: "1px solid rgba(25, 24, 35, 0.65)",
        borderRadius: "2px",
        background: "#fff", 
      },
    },
  }));
  const classes = useStyles();

  return (
    <>
      <Typography variant="label" className="form-group">
        Number of Colors
      </Typography>
      <Box display="flex"  gap={2} marginTop={1.2} marginBottom={7}   >
      <TextField
        type="number"
        inputProps={{ step: 1, min: 0, max: 24 }} // adding max value limit
        variant="outlined"
        label=""
        value={props.value}
        onChange={handleChange}
        fullWidth 
        className={classes.outlinedTextField}
      />
     <Stack spacing={0.5} direction="row" >
      <Button  className={classes.button1} variant="custom" onClick={() => props.onButtonClick("subtract")}
      >
        <RemoveOutlinedIcon />
      </Button> 
      <Button  className={classes.button2} variant="custom"  onClick={() => props.onButtonClick("add")} >
      <AddOutlinedIcon />
    </Button >
      </Stack>
      </Box>
    </>
  );
};

export default NumberInput;
